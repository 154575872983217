import React from 'react'
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Card } from '@mui/material';

const Copyright = () => {
  return (
    <div style={{paddingTop:20}}>
      <Card style={{paddingTop:10,paddingBottom:10,textAlign:"center",background:"linear-gradient(356deg, rgba(255,255,255,0.49343487394957986) 0%, rgba(0,236,255,0.4654236694677871) 100%)"}}><CopyrightIcon/> Copyright Barokah Accu 2023. All Right Reserved</Card>
    </div>
  )
}

export default Copyright
