import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Carousel from '../components/CarouselComponent'
import Cards from '../components/Cards'
import Contact2 from "../components/Contact2"
import {Container, Row} from 'react-bootstrap-v5'
import { toAbsoluteUrl } from '../_metronic/helpers/AssetHelpers'
import Gmaps from '../components/Gmaps'
import Card from '@mui/material/Card'
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import Copyright from '../components/Copyright'

import CardsDynamo from '../components/CardsDynamo'

import { Helmet } from 'react-helmet-async'
import VideoBG from '../components/VideoBG'
const HomeBAC = () => {

  // useEffect(()=>{
  //   fetch("http://localhost:8000/company").then(res=>{
  //     return res.json()
  //   }).then(result=>{
  //     console.log(result)
  //   })
  // },[])


  return (
  <div
    className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    style={{
      // backgroundImage: `url(${toAbsoluteUrl('/media/accuMobil/testWallpaper.png')})`,
    }}
  >
   <Helmet>
    <title>BAROKAH ACCU</title>
    <meta name="description" content="ACCU MOBIL DAN MOTOR CIPAYUNG DINAMO CIPAYUNG RADIATOR CIPAYUNG SERVICE DINAMO SERVICE RADIATOR JABODETABEK BAROKAH ACCU"/>
    <link rel="canonical" href="/home"/>
    </Helmet>
    
     
      <VideoBG/>
   
   
     
      <Container>
        
      <Contact2/>

      <Carousel/>
    
      <div style={{paddingTop:20,paddingBottom:20}}>
        <Card style={{padding:10,background:"linear-gradient(356deg, rgba(255,255,255,0) 0%, rgba(255,252,0,0.8127626050420168) 100%)"}}>
          <Card style={{textAlign:"center",background:"linear-gradient(356deg, rgba(255,255,255,0.49343487394957986) 0%, rgba(0,198,255,0.4654236694677871) 100%)"}}><h1 style={{color:"red",fontWeight:"bold",textShadow:" 2px 2px 1px rgba(0,0,0,0.7)",fontFamily:"Helvetica"}}>ACCU MOBIL</h1></Card>
        <Cards />
        </Card>
        
        </div>
        <div style={{paddingBottom:20}}>
        <Card style={{padding:10,background:"linear-gradient(356deg, rgba(255,255,255,0) 0%, rgba(255,252,0,0.8127626050420168) 100%)"}}>
          <Card style={{textAlign:"center",background:"linear-gradient(356deg, rgba(255,255,255,0.49343487394957986) 0%, rgba(0,198,255,0.4654236694677871) 100%)"}}><h1 style={{color:"red",fontWeight:"bold",textShadow:" 2px 2px 1px rgba(0,0,0,0.7)",fontFamily:"Helvetica"}}>SERVICE DYNAMO DAN RADIATOR</h1></Card>
 <CardsDynamo/>
        </Card>
        </div>
        <Card style={{background:"linear-gradient(356deg, rgba(255,255,255,0) 0%, rgba(255,252,0,0.8127626050420168) 100%)"}}>
       <Gmaps/></Card>
      </Container>
      <Copyright/>
    </div>  
  
  )
}

export default HomeBAC
