import React, { useState } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Col, Row } from 'react-bootstrap-v5';


const CardsDynamo = () => {
    const[cards,setCards]=useState([{name:"Radiator",image:"/media/accuMobil/radiator.jpg"},{name:"Dynamo",image:"/media/accuMobil/dynamo.jpg"}])
    const [numCard, setNumCard] = useState<number>(0)
    const [modal, setModal] = useState<boolean>(false)

    const OnMouseOverHandler = (over: boolean, num: number) => {
        if (over) {
          setNumCard(num)
       
        } else {
          setNumCard(-1)
      
        }
      }
      const onCardClickHandler = (num: boolean) => {
        setModal(num)
      }
  return (
    <div> 
        <Row style={{padding:10}}>
        {cards.map((item, i) => (
            <Col xs="3">
        <Card sx={{ maxWidth: 345 }} elevation={i === numCard ? 1 : 12} onMouseOver={() => OnMouseOverHandler(true, i)}onMouseLeave={() => OnMouseOverHandler(false, i)}style={{background:"linear-gradient(356deg, rgba(255,255,255,0.49343487394957986) 0%, rgba(0,236,255,0.4654236694677871) 100%)"}}>
    <CardActionArea>
      <CardMedia
        component="img"
        height='100%'
        width="100%"
        image={item.image}
        alt="green iguana"
        style={{paddingTop:5,paddingLeft:5,paddingRight:5}}
      />
      <div style={{textAlign:"center",color:"red",paddingTop:10,paddingBottom:10,fontWeight:"BOLD"}}>
      {item.name}
      </div>
  
    </CardActionArea>
  </Card> </Col>    ))}</Row></div>
  )
}

export default CardsDynamo