import React from 'react'
import Navbar from "../../components/Navbar"

const Artikel = () => {
  return (
    <div>
      <Navbar/>
    </div>
  )
}

export default Artikel