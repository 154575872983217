import * as React from 'react'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import Paper from '@mui/material/Paper'
import Grow from '@mui/material/Grow'
import FormControlLabel from '@mui/material/FormControlLabel'
import {Theme} from '@mui/material/styles'
import {Row} from 'react-bootstrap-v5'
import { Card, CardMedia, Typography, styled } from '@mui/material'

const icon = (
  <Paper sx={{m: 1}} elevation={4}>
    <Box component='svg' sx={{width: 100, height: 100}}>
      <Box
        component='polygon'
        sx={{
          fill: (theme: Theme) => theme.palette.common.white,
          stroke: (theme) => theme.palette.divider,
          strokeWidth: 1,
        }}
        points='0,100 50,00, 100,100'
      />
    </Box>
  </Paper>
)

interface mansoryInterface {
  mansoryOpen: boolean
  desc:string
  name:string
  image1:string
  image2:string
  image3:string
  image4:string
  video:string
}

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));
const SimpleGrow: React.FC<mansoryInterface> = (props) => {
  const [checked, setChecked] = React.useState(false)

  return (
    <div>
      <CardMedia
                component='video'
                height='100%'
                width="100%"
                image={process.env.PUBLIC_URL+props.video}
                autoPlay
                style={{paddingTop:5,paddingLeft:5,paddingRight:5}}
                loop
              />
      <Row xs={8}>
        <Box sx={{display: 'responsive'}}>
          <Grow in={props.mansoryOpen} {...(props.mansoryOpen ? {timeout: 1000} : {})}style={{padding:10}}>
            <img style={{width:100}} src={props.image1}/>
          </Grow>
          {/* Conditionally applies the timeout prop to change the entry speed. */}
          <Grow
            in={props.mansoryOpen}
            style={{transformOrigin: '0 0 0',padding:10}}
            {...(props.mansoryOpen ? {timeout: 2000} : {})}
          >
            <img style={{width:100}} src={props.image2}/>
          </Grow>
          <Grow in={props.mansoryOpen} {...(props.mansoryOpen ? {timeout: 3000} : {})} style={{padding:10}}>
          <img style={{width:100}} src={props.image3}/>
          </Grow>
          {/* Conditionally applies the timeout prop to change the entry speed. */}
          <Grow
            in={props.mansoryOpen}
            style={{transformOrigin: '0 0 0',padding:10}}
            {...(props.mansoryOpen ? {timeout: 4000} : {})}
          >
            <img style={{width:100}} src={props.image4}/>
          </Grow>
        </Box>
        <Grow
          in={props.mansoryOpen}
          style={{transformOrigin: '0 0 0'}}
          {...(props.mansoryOpen ? {timeout: 2000} : {})}
        >
          <Card style={{background:"linear-gradient(356deg, rgba(255,255,255,0.6558998599439776) 0%, rgba(255,252,0,1) 100%)"}}>
          <Div style={{textAlign:"justify",color:"red" ,display:"inline-block",background:"linear-gradient(356deg, rgba(255,255,255,0.6558998599439776) 0%, rgba(255,252,0,1) 100%)"}}>  
                 {props.desc}
                </Div>
                </Card>
        </Grow>
        {/* <Grow in={props.mansoryOpen} {...(props.mansoryOpen ? {timeout: 3000} : {})}>
          {icon}
        </Grow> */}
        {/* Conditionally applies the timeout prop to change the entry speed. */}
        {/* <Grow
          in={props.mansoryOpen}
          style={{transformOrigin: '0 0 0'}}
          {...(props.mansoryOpen ? {timeout: 4000} : {})}
        >
          {icon}
        </Grow> */}
      </Row>
    </div>
  )
}
export default SimpleGrow
