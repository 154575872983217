import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {useState} from 'react'
import {Col, Row} from 'react-bootstrap-v5'
import ModalCard from './ModalCard'
import { Item } from 'react-bootstrap/lib/Breadcrumb'
import { Container } from 'react-bootstrap/lib/Tab'

export default function MediaCard() {
  const [numCard, setNumCard] = useState<number>()
  const [mansory, setMansory] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false)
  const [image1,setImage1] = useState<string>("")
  const [image2,setImage2] = useState<string>("")
  const [image3,setImage3] = useState<string>("")
  const [image4,setImage4] = useState<string>("")
  const [desc,setDesc] = useState<string>("")
  const [name,setName]=useState<string>("")
  const [video,setVideo]=useState<string>("")
  const [cards, setCards] = useState([
    {
      name: 'GS HYBRID',
      desc: 'Aki GS Hybrid diklaim memiliki penguapan air aki yang lebih rendah, sehingga tidak perlu menambahkan air aki 4 sampai 5 kali dibandingkan aki konvensional atau aki basah. Saat pertama kali membeli aki GS hybrid, sudah tersedia air zuur standar pabrik di dalamnya. Selain itu juga aki hybrid GS sudah terisi setrum, sehingga sudah siap pakai.',
      image:`/media/accuMobil/gshybrid.jpg`,
      image1:`/media/accuMobil/gshybrid1.jpg`,
      image2:`/media/accuMobil/gshybrid2.jpg`,
      image3:`/media/accuMobil/gshybrid3.jpg`,
      image4:`/media/accuMobil/gshybrid4.jpg`,
      video:`/media/accuMobil/gsHybrid.mp4`
    },
    {
      name: 'GS MF',
      desc: 'Aki Maintenance Free (MF)  lebih dikenal sebagai “aki kering”. Pada dasarnya, semua jenis aki (kering dan basah) tetap menggunakan cairan elektrolit.  Namun, pada aki kering, kadar berat jenis elektrolitnya lebih pekat atau kental. Aki kering di desain khusus untuk mencegah penguapan air dari dalam aki, sehingga penambahan air tidak lagi diperlukan. Karena tidak memerlukan penambahan air, maka aki jenis ini disebut aki Maintenanfe Free (MF) atau bebas perawatan.',
      image:`/media/accuMobil/gsmf.jpg`,
      image1:`/media/accuMobil/gsmf1.jpg`,
      image2:`/media/accuMobil/gsmf2.jpg`,
      image3:`/media/accuMobil/gsmf3.jpg`,
      image4:`/media/accuMobil/gsmf4.jpg`,
      video:`/media/accuMobil/gsMF.mp4`
    },
    {
      name: 'GS PREMIUM',
      desc: 'Aki Premium bisa disebut aki konvensional, penguapan air akinya terbilang cukup cepat, sedangkan Hybrid bisa disebut gabungan dari aki konvensional dan MF (maintenance free) penguapan nya terbilang minim',
      image:`/media/accuMobil/gspremium.jpg`,
      image1:`/media/accuMobil/gspremium1.jpg`,
      image2:`/media/accuMobil/gspremium2.jpg`,
      image3:`/media/accuMobil/gspremium3.jpg`,
      image4:`/media/accuMobil/gspremium4.jpg`,
      video:`/media/accuMobil/gsPremium.mp4`
    },
    {
      name: 'INCOE MF',
      desc: 'Ini adalah jenis aki kering, dan biasanya digunakan untuk mobil Jepang atau mobil Eropa. Aki Incoe ini juga disediakan dalam beberapa spesifikasi disesuaikan dengan kebutuhan mobil yang ada di Indonesia.',
      image:`/media/accuMobil/incoemf.jpg`,
      image1:`/media/accuMobil/incoemf1.jpg`,
      image2:`/media/accuMobil/incoemf2.jpg`,
      image3:`/media/accuMobil/incoemf3.jpg`,
      image4:`/media/accuMobil/incoemf4.jpg`,
      video:`/media/accuMobil/incoeMF.mp4`
    },
    {
      name: 'INCOE PREMIUM',
      desc: 'Jenis pertama ini adalah jenis aki basah Incoe. Diberi nama Incoe Premium Japan Vehicle (JIS) sering digunakan sebagai aki mobil di Indonesia . Aki mobil ini, memiliki banyak varian lain dengan beberapa spesifikasi yang berbeda juga, mulai dari aki Incoe NS40, NS60, N50, N70, N100, N120, N150, dan N200.',
      image:`/media/accuMobil/incoepremium.jpg`,
      image1:`/media/accuMobil/incoepremium1.jpg`,
      image2:`/media/accuMobil/incoepremium2.jpg`,
      image3:`/media/accuMobil/incoepremium3.jpg`,
      image4:`/media/accuMobil/incoepremium4.jpg`,
      video:`/media/accuMobil/incoePremium.mp4`
    },
    {
      name: 'INCOE GOLD',
      desc: 'Aki Incoe selanjutnya ini berjenis hybrid yang telah terisi accu zuur dan diklaim tidak perlu terlalu sering menambah air aki atau di cas ulang. Untuk aki hybrid ini, memiliki jenis lainnya juga mulai dari NS40, NS60, N50, N70, dan N120.',
      image:`/media/accuMobil/ingo.jpg`,
      image1:`/media/accuMobil/ingo1.jpg`,
      image2:`/media/accuMobil/ingo2.jpg`,
      image3:`/media/accuMobil/ingo3.jpg`,
      image4:`/media/accuMobil/ingo4.jpg`,
      video:`/media/accuMobil/incoeGold.mp4`
    },
  ])

  const OnMouseOverHandler = (over: boolean, num: number) => {
    if (over) {
      setNumCard(num)
      setMansory(true)
    } else {
      setNumCard(-1)
      setMansory(false)
    }
  }
  const onCardClickHandler = (num: boolean) => {
    setModal(num)
  }

  const onCardClickHandlerItems = (num: boolean,image1:string,image2:string,image3:string,image4:string,desc:string,name:string,video:string) => {
    setModal(num)
    setDesc(desc)
    setName(name)
    setImage1(image1)
    setImage2(image2)
    setImage3(image3)
    setImage4(image4)
    setVideo(video)
  }
  return (
    <div>
      <Row style={{paddingTop: 10}}>
        {cards.map((item, i) => (
          <Col xs='3' style={{paddingTop: 10}} key={i}>
            <Card
              sx={{maxWidth: 345}}
              elevation={i === numCard ? 1 : 12}
              onMouseOver={() => OnMouseOverHandler(true, i)}
              onMouseLeave={() => OnMouseOverHandler(false, i)}
              onClick={() => onCardClickHandlerItems(true,item.image1,item.image2,item.image3,item.image4,item.desc,item.name,item.video)}
              key={i}
              style={{background:"linear-gradient(356deg, rgba(255,255,255,0.49343487394957986) 0%, rgba(0,236,255,0.4654236694677871) 100%)"}}
            >
              {i === numCard?  
              <CardMedia
                component='video'
                height='100%'
                width="100%"
                image={process.env.PUBLIC_URL+item.video}
                autoPlay
                style={{paddingTop:5,paddingLeft:5,paddingRight:5}}
                loop
              />
              : 
              <CardMedia
                component='img'
                height='100%'
                width="100%"
                image={process.env.PUBLIC_URL+item.image}
                alt='green iguana'
                style={{paddingTop:5,paddingLeft:5,paddingRight:5}}
              />}
             
              <div style={{textAlign:"center",color:"red",paddingTop:10,paddingBottom:10,fontWeight:"BOLD"}}>
      {item.name}
      </div>
              <CardActions>
              </CardActions>
            </Card>
          </Col>
        ))}
      </Row>
      <ModalCard modal={modal} image1={image1} image2={image2} image3={image3} image4={image4} desc={desc} name={name} onCardClickHandler={onCardClickHandler} video={video}/>
    </div>
  )
}
