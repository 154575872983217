import React from 'react'
import Navbar from './Navbar'

const VideoBG = () => {
  return (
    <div>
            
        <video src={process.env.PUBLIC_URL+'/media/accuMobil/Background.mp4'} autoPlay loop muted style={{width:"100%",height:"100%",objectFit:"cover",position:"fixed",zIndex:-1}}/>
  
        <Navbar />
     
        <h1 style={{width:"100%",height:"100%",top:0,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",color:"white"}}>
                SELAMAT DATANG DI 
                <img src={process.env.PUBLIC_URL+"/media/accuMobil/logo1.png"} style={{width:"20%"}}/>
            </h1>
           
           
  
    </div>
  )
}

export default VideoBG