import { Fab } from '@mui/material'
import React, { useState } from 'react'
import { Col, Row ,Modal,Button} from 'react-bootstrap-v5'
import Card from '@mui/material/Card'
import NavigationIcon from '@mui/icons-material/Navigation';


const Gmaps = () => {
  const [close,setClose]=useState(false)

  const closeHandle = (close:boolean)=>{
    setClose(close)
  }

  return (
<div>
<Modal show={close} >
        <Modal.Header >
          <Modal.Title>LOKASI BAROKAH ACCU</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1667.2816736235516!2d106.89010502336407!3d-6.330972636514385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ed220bba7f0d%3A0x104645ffabd2c07c!2sBarokah%20Accu!5e0!3m2!1sid!2sid!4v1684768841069!5m2!1sid!2sid" width="100%" height="100%" loading="lazy" style={{border:0}} ></iframe>
        
        <Card style={{paddingTop:20,paddingLeft:10,background:"linear-gradient(167deg, rgba(0,255,252,1) 0%, rgba(255,255,255,1) 100%)"}}>
        <div style={{color:"red",fontWeight:"bold"}}>
          <h2 style={{textAlign:"center",color:"white"}}>ALAMAT LENGKAP</h2>
          <p>Toko Barokah Accu, Jalan Raya Cipayung No.8, RT.8/RW.2, Cipayung (Seberang POM Bensin )</p>
          <p>KOTA JAKARTA TIMUR - CIPAYUNG</p>
          <p>DKI JAKARTA</p>
        </div>
        </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>closeHandle(!close)}>
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>
      <Card
             style={{background:"linear-gradient(356deg, rgba(255,255,255,0.49343487394957986) 0%, rgba(0,236,255,0.4654236694677871) 100%)",paddingTop:20,paddingLeft:20,paddingBottom:20,paddingRight:20}}
      >

      <Fab variant="extended" color="primary"  onClick={()=>closeHandle(!close)} style={{width:"100%"}}>
  <NavigationIcon sx={{ mr: 1 }} />
  Navigate To Barokah Accu
</Fab>
    </Card>
</div>
  )
}

export default Gmaps