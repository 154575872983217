import { Avatar, Card } from '@mui/material'
import React from 'react'
import { Col, Row } from 'react-bootstrap-v5'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Marquee from "react-fast-marquee";

const Contact2 = () => {
  return (
   
    <div style={{textAlign:"center",paddingTop:20}}>
        <Row>
        <Col>
        <Card style={{background:" linear-gradient(356deg, rgba(0,236,255,1) 0%, rgba(245,255,0,1) 100%)",paddingTop:10,paddingBottom:10}}>
        <Avatar style={{width:55,display:"block",marginLeft: "auto",marginRight: "auto"}}alt="Remy Sharp" src="/media/accuMobil/accu_icon.png" />
        <h5 style={{color:"red"}}>Spesialis : ACCU MOBIL DAN MOTOR </h5>
        <h5 style={{color:"red"}}>Contact</h5>
        <p><a href="https://api.whatsapp.com/send?phone=6281299888726&text=Hallo Admin Barokah Accu"style={{fontSize:15}} ><img src='/media/accuMobil/logowa.png' style={{width:25}}/> WhatsApp1 / </a><a href="tel:081299888726" style={{fontSize:15}}><img src='/media/accuMobil/telpon.png' style={{width:25}}/> Telepon1</a></p>
        <p><a href="https://api.whatsapp.com/send?phone=6281295295292&text=Hallo Admin Barokah Accu"style={{fontSize:15}} ><img src='/media/accuMobil/logowa.png' style={{width:25}}/> WhatsApp2 / </a><a href="tel:081295295292" style={{fontSize:15}}><img src='/media/accuMobil/telpon.png' style={{width:25}}/> Telepon2</a></p>
        <p><a href="https://api.whatsapp.com/send?phone=6281224568852&text=Hallo Admin Barokah Accu"style={{fontSize:15}} ><img src='/media/accuMobil/logowa.png' style={{width:25}}/> WhatsApp3 / </a><a href="tel:081224568852" style={{fontSize:15}}><img src='/media/accuMobil/telpon.png' style={{width:25}}/> Telepon3</a></p>
        </Card>
        </Col>
        
        <Col>
        <Card style={{background:" linear-gradient(356deg, rgba(0,236,255,1) 0%, rgba(245,255,0,1) 100%)",paddingTop:10,paddingBottom:10}}>
        <Avatar style={{width:55,display:"block",marginLeft: "auto",marginRight: "auto"}}alt="Remy Sharp" src="/media/accuMobil/dinamo_icon.png" />
        <h5 style={{color:"red"}}>Spesialis : DYNAMO </h5>
        <h5 style={{color:"red"}}>Contact</h5>
        <p><a href="https://api.whatsapp.com/send?phone=6282124029685&text=Hallo Admin Barokah Accu"style={{fontSize:15}} ><img src='/media/accuMobil/logowa.png' style={{width:25}}/> WhatsApp1 / </a><a href="tel:082124029685" style={{fontSize:15}}><img src='/media/accuMobil/telpon.png' style={{width:25}}/> Telepon1</a></p>
        <p><a href="https://api.whatsapp.com/send?phone=6285319535009&text=Hallo Admin Barokah Accu"style={{fontSize:15}} ><img src='/media/accuMobil/logowa.png' style={{width:25}}/> WhatsApp2 / </a><a href="tel:085319535009" style={{fontSize:15}}><img src='/media/accuMobil/telpon.png' style={{width:25}}/> Telepon2</a></p>
        <p><a href="https://api.whatsapp.com/send?phone=6282112992893&text=Hallo Admin Barokah Accu"style={{fontSize:15}} ><img src='/media/accuMobil/logowa.png' style={{width:25}}/> WhatsApp3 / </a><a href="tel:082112992893" style={{fontSize:15}}><img src='/media/accuMobil/telpon.png' style={{width:25}}/> Telepon3</a></p>
        </Card>
        </Col>
       
       
        <Col>
        <Card style={{background:" linear-gradient(356deg, rgba(0,236,255,1) 0%, rgba(245,255,0,1) 100%)",paddingTop:10,paddingBottom:10}}>
        <Avatar style={{width:55,display:"block",marginLeft: "auto",marginRight: "auto"}}alt="Remy Sharp" src="/media/accuMobil/radiator_icon.png" />
        <h5 style={{color:"red"}}>Spesialis : RADIATOR </h5>
        <h5 style={{color:"red"}}>Contact</h5>
        <p><a href="https://api.whatsapp.com/send?phone=6285289716310&text=Hallo Admin Barokah Accu"style={{fontSize:15}} ><img src='/media/accuMobil/logowa.png' style={{width:25}}/> WhatsApp1 / </a><a href="tel:085289716310" style={{fontSize:15}}><img src='/media/accuMobil/telpon.png' style={{width:25}}/> Telepon1</a></p>
        <p><a href="https://api.whatsapp.com/send?phone=6281283685403&text=Hallo Admin Barokah Accu"style={{fontSize:15}} ><img src='/media/accuMobil/logowa.png' style={{width:25}}/>WhatsApp2 / </a><a href="tel:081283685403" style={{fontSize:15}}><img src='/media/accuMobil/telpon.png' style={{width:25}}/> Telepon2</a></p>
        <p><a href="https://api.whatsapp.com/send?phone=6281210170372&text=Hallo Admin Barokah Accu"style={{fontSize:15}} ><img src='/media/accuMobil/logowa.png' style={{width:25}}/> WhatsApp3 / </a><a href="tel:081210170372" style={{fontSize:15}}><img src='/media/accuMobil/telpon.png' style={{width:25}}/> Telepon3</a></p>
        </Card>
        </Col>
        </Row>
        <div style={{paddingTop:20}}>
        <Card style={{background:" linear-gradient(356deg, rgba(0,236,255,1) 0%, rgba(245,255,0,1) 100%)",paddingTop:10,paddingBottom:10}}>
        <Marquee speed={70}>
          <h1 style={{color:"red"}}>
  ^^ INFORMASI LEBIH LANJUT SILAHKAN MENGHUBUNGI NOMOR WHATSAPP DIATAS ^^ 
  </h1>
</Marquee>
        </Card>
        </div>
       
        </div>
    
  )
}

export default Contact2