import React from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import Mansory from './Mansory'

interface modalInterface {
  modal: boolean
  name:string
  image1:string
  image2:string
  image3:string
  image4:string
  desc:string
  video:string
  onCardClickHandler: (num: boolean) => void
}

const ModalCard: React.FC<modalInterface> = (props) => {
  return (
    <Modal show={props.modal}>
      <Modal.Header>
        <Modal.Title>{props.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Mansory mansoryOpen={props.modal} image1={props.image1}  image2={props.image2} image3={props.image3} image4={props.image4} desc={props.desc} name={props.name} video={props.video}/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => props.onCardClickHandler(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalCard
