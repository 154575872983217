import { Carousel } from "react-bootstrap-v5";

function UncontrolledExample() {

  
  return (
    
    <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel" style={{paddingTop:20}}>
  <div className="carousel-inner" style={{borderRadius:250,borderStyle:'outset',borderWidth:'5px',borderColor:" rgb(0,255,248)",boxShadow:"5px 5px 8px 10px #888888"}} >
    <div className="carousel-item active" data-bs-interval="10000" >
      <img src={process.env.PUBLIC_URL+'/media/accuMobil/banner1.jpg'} className="d-block w-100" alt="..." style={{height:400}}></img>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={process.env.PUBLIC_URL+'/media/accuMobil/SERTIFIKAT2013.jpg'} className="d-block w-100" alt="..." style={{height:400}}></img>
    </div>
    <div className="carousel-item">
      <img src={process.env.PUBLIC_URL+'/media/accuMobil/SERTIFIKAT2019.jpg'} className="d-block w-100" alt="..." style={{height:400}}></img>
    </div>
    <div className="carousel-item">
    <video src={process.env.PUBLIC_URL+'/media/accuMobil/Background2.mp4'} autoPlay height="400" width={"100%"} style={{objectFit:"cover"}} controls/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  );
}

export default UncontrolledExample;